import React from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "./personal.module.scss"
import Usage from "../../components/sections/usage/usage"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"
import { TM } from "../../components/common/typography/trademark"

const PersonalUsage = () => (
  <Layout>
    <HeaderBanner title="FREE Personal Usage" />
    <div className={styles.pageContainer}>
      <Breadcrumb
        firstLink="/ironcap-x"
        firstLinkTitle={
          <>
            IronCAP X<TM />
          </>
        }
        secondLink="/ironcap-x/personal"
        secondLinkTitle="FREE Personal Usage"
      />
      <Usage usage="Personal" />
    </div>
    <CTABanner tagline={LearnMoreTagline} CTA="video" CTADetail="how video" />
  </Layout>
)

export default PersonalUsage
